.search-bar {
  &__card {
    border-radius: 0;
    margin: 10px 0;
  }

  &__card-content {
    padding: 12px 0 12px 24px !important;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__form-control {
    display: flex;
    width: 100%;
  }

  &__block {
    display: inline-block;
  }

  &__select {
    min-width: 220px;
    width: 220px;
    &::before {
      border-bottom: 1px solid #309cea !important;
    }
  }

  &__icon {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  &__text-field {
    min-width: 220px;
    width: 220px;
    margin: 0 24px !important;
  }

  &__icons {
    border-left: 1.5px solid #e0e0e0;
  }

  &__side-icon-search {
    display: inline-block;
    padding: 5px 12px 2px 24px;
  }

  &__side-icon-clear {
    display: inline-block;
    padding: 5px 24px 2px 12px;
  }

  &__side-icon-item {
    color: #979797;
    cursor: pointer;
  }

  &__add-minus-icon-block {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  &__add-minus-icon {
    color: #979797;
    cursor: pointer;
  }

  &__search-terms {
    width: calc(100% - 141px) !important;
    display: flex;
    justify-content: space-between;
  }

  &__date-picker{
    display: flex;
  }

  &__date-picker-start {
    margin-right: 15px !important;
  }

  &__search-fields {
    display: flex;
    align-items: center;
  }

  &__calendar {
    background-color: #309cea;
  }
}
