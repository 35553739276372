.user-kyc-documents {
  &__card {
    max-width: 345px;
  }

  &__title {
    margin-bottom: 24px !important;
    letter-spacing: 0.15px !important;
    font-weight: 600 !important;
    padding-left: 26px;
    padding-top: 26px;
  }

  &__paper {
    padding: 20px 24px 24px 24px;
  }
}
