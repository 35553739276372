.admin-table {
  &__root {
    width: 100%;
    margin-top: 24px;
  }

  &__table {
    min-width: 1020px;
    width: 100%;
  }

  &__table-wrapper {
    overflow-x: auto;
  }

  &__link {
    cursor: pointer;
    color: #309cea;
    text-decoration: none;
    letter-spacing: 0.4px;
  }
  &__label {
    letter-spacing: 0.15px;
    padding: 16px;
    padding-bottom: 0px;
    font-weight: 500;
    padding-left: 24px;
  }

  &__headers {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1px;
  }

  &__content {
    font-weight: 500;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__empty-table {
    padding: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__select-icon {
    padding-left: 10px;
  }

  &__active {
    color: #00a41a;
  }

  &__banned {
    color: #e23328;
  }

  &__icon {
    cursor: pointer;
    color: #309cea;
  }

  &__cell {
    padding: 4px 16px !important;
  }

  &__info {
    text-align: center;
    padding: 4px 16px !important;
  }

  &__title {
    opacity: 0.54;
  }

  &__popper {
    padding: 8px 10px;
  }
  &__value {
    padding-bottom: 8;
  }
}
