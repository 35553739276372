.user-settings {
  &__paper {
    padding: 20px 24px 12px 24px;
  }

  &__formControl {
    margin-bottom: 24px !important;
  }

  &__gridRow {
    justify-content: space-between;
    padding-right: 40px;
  }

  &__gridRowCenter {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-bottom: 24px !important;
    letter-spacing: 0.15px !important;
    font-weight: 600 !important;
  }

  &__subtitle {
    color: #757575;
  }
}
