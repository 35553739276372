.user-label {
  &__icon {
    width: 20px !important;
    height: 20px !important;
    margin: 7px 4px;
    cursor: pointer;
    opacity: 0.6;
  }

  &__label {
    height: 32px;
    padding-left: 16px;
    border-radius: 24px;
    width: auto !important;
    cursor: pointer;
    letter-spacing: 0.15px;
    font-weight: 600;
  }

  &__labelName {
    padding-top: 5px;
    color: #ffffff;
    font-size: 14px !important;
    margin-right: 7px !important;
    letter-spacing: 0.25px !important;
  }

  &__paper {
    padding: 20px 24px 24px 24px;
  }

  &__title {
    margin-bottom: 24px !important;
    letter-spacing: 0.15px !important;
    font-weight: 600 !important;
  }
}
