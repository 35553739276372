.new-deposit-modal {
  &__paper {
    display: block !important;
    margin: 100px auto;
    width: 360px !important;
    background-color: #fff;
    padding: 16px;
  }

  &__textField {
    margin-left: 8px;
    margin-right: 8px;
    width: 312px;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }
}
