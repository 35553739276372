.user-kyc {
  &__paper {
    padding: 20px 24px 12px 0px;
  }

  &__gridRow {
    justify-content: space-between;
    padding-right: 24px;
  }

  &__gridColumn {
    display: flex !important;
    flex-direction: column;
  }

  &__gridRowWithDivider {
    border-color: #e8e8e8 !important;
    border: 0;
    border-left: 1px;
    border-style: solid;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
    height: 15px;
  }

  &__title {
    margin-bottom: 24px !important;
    margin-left: 24px !important;
    letter-spacing: 0.15px !important;
    font-weight: 600 !important;
  }

  &__info {
    opacity: 0.72;
    letter-spacing: 0.25px !important;
    margin-bottom: 16px !important;
  }

  &__level {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
