.user-partner-data {
  &__grid-row {
    justify-content: space-between;
  }

  &__title {
    color: #757575;
  }

  &__paper {
    padding: 24px;
  }

  &__label {
    letter-spacing: 00.15px !important;
    padding-bottom: 0px;
    font-weight: 600 !important;
  }
}
