.info-table {
  &__root {
    width: 100%;
    margin-top: 24px;
  }

  &__table {
    min-width: 1020px;
    width: 100%;
  }

  &__table-wrapper {
    overflow-x: auto;
  }

  &__link {
    cursor: pointer;
    color: #309cea;
    text-decoration: none;
    letter-spacing: 0.4px;
  }

  &__attachment {
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__label {
    letter-spacing: 0.15px;
    padding: 16px;
    padding-bottom: 0px;
    font-weight: 600;
  }

  &__headers {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1px;
  }

  &__content {
    font-weight: 500;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__empty-table {
    padding: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__select-icon {
    padding-left: 10px;
  }

  &__active {
    color: #00a41a;
  }

  &__banned {
    color: #e23328;
  }

  &__in-process {
    color: #ffaa00;
  }

  &__grey-icon {
    cursor: pointer;
    color: #979797;
  }

  &__cell {
    padding: 4px 0px;
  }
}
