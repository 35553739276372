.dashboard {
  &__main {
    display: flex;
    justify-content: space-between;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
  }

  &__paper {
    display: flex;
    flex-direction: column;
    width: 23%;
    min-height: 100px;
    padding: 20px;
  }

  &__heading {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #6e6e6e;
    margin-bottom: 5px;
  }

  &__counter {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.0015em;
    color: #309cea;
  }

  &__counterRed {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.0015em;
    color: #e23328;
  }

  &__activity {
    display: flex;
    font-family: Roboto;
    min-height: 300px;
    padding: 5px;
    margin-top: 2%;
    flex-direction: column;
  }

  &__activityHeading {
    font-weight: normal;
    padding: 15px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
}
