.extra-documents-modal {
  &__status {
    margin-bottom: 5px !important;
  }

  &__file {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  &__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }

  &__red {
    color: #e23328 !important;
  }

  &__green {
    color: #00a41a !important;
  }

  &__yellow {
    color: #e3b930 !important;
  }

  &__blue {
    color: #3598d5 !important;
  }
}
