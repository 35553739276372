.user-limits {
  &__paper {
    display: flex;
    align-items: center;
  }
  &__add_minus_icon_block {
    display: flex;
    align-items: flex-end;
    position: absolute;
    margin-bottom: 449px;
    margin-left: 135px;
  }

  &__add-minus-icon {
    color: #979797;
    cursor: pointer;
  }
}
