.action-buttons {
  &__beneficiaryInfoBlockBodyItem {
    padding: 12px 0px;
  }

  &__blockButtons {
    text-align: right;
    width: 100%;
  }

  &__buttonReject {
    background: #e23328 !important;
    color: #fff !important;
    margin: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  &__buttonAccept {
    background: #099744 !important;
    color: #fff !important;
    margin: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  &__buttonArchive {
    background: #309cea !important;
    color: #fff !important;
    margin: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  &__buttonIcon {
    padding-left: 4px;
    margin-right: -10px;
  }
}
