.unmatched-deposit-info {
  &__depositInfo {
    display: block;
    width: 100%;
    padding: 0px 24px 0px 0px !important;
  }

  &__depositInfoBlockBody {
    display: inline-block;
    width: 100%;
    padding-left: 24px;
  }
  &__depositInfoBlock {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    margin-bottom: 24px;
  }

  &__emailTitle {
    color: rgba(0, 0, 0, 0.87);
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 20px !important;
    padding-left: 24px;
    padding-top: 20px;
  }

  &__block {
    display: inline-block;
    max-width: 100%;
    width: 100%;
  }

  &__blockItem {
    display: inline-block;
    max-width: 50%;
    padding: 12px 0px 12px;
    width: 100%;
  }
  &__blockItemTitle {
    color: rgba(0, 0, 0, 0.87);
    font-style: normal;
    font-weight: normal !important;
    font-size: 12px !important;
    padding-bottom: 8px;
    opacity: 0.54;
    letter-spacing: 0.4px !important;
    text-transform: capitalize;
  }
  &__blockItemInfo {
    color: rgba(0, 0, 0, 0.87);
    font-style: normal;
    font-weight: normal !important;
    font-size: 12px !important;
    letter-spacing: 0.4px !important;
  }
  &__divider {
    margin: 12px 0px !important;
  }

  &__blockButtons {
    text-align: right;
    width: 100%;
  }

  &__buttonReject {
    background: #e23328 !important;
    color: #fff !important;
    margin: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  &__buttonAccept {
    background: #309cea !important;
    color: #fff !important;
    margin: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    letter-spacing: 0.75px !important;
  }

  &__buttonIcon {
    padding-left: 4px;
    margin-right: -10px;
  }
  &__red {
    color: #e23328 !important;
  }
  &__green {
    color: #00a41a !important;
  }
  &__yellow {
    color: #e3b930 !important;
  }
  &__depositInfoBlockBodyItem {
    padding: 12px 0px;
  }
  &__editInput {
    display: none;
  }
}
