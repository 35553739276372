.navbar {
  &__img{
    width: 24px;
    height: 24px;
  }
  &__appBar {
    background-color: #3598d5 !important;
  }
  &__navbarHidden {
    display: none !important;
  }
  &__grow {
    flex-grow: 1;
    cursor: pointer;
  }
  &__menuButton {
    margin-left: 12px;
    margin-right: 20px;
  }
  &__drawer {
    width: 256px;
    flex-shrink: 0 !important;
  }
  &__drawerPaper {
    width: 256px;
    padding: 0px 8px;
  }
  &__drawerHeader {
    display: flex;
    align-items: center;
    padding: 0px 8px;
    @media (min-width: 0px) and (orientation: landscape) {
      min-height: 40px;
    }
    @media (min-width: 600px) {
      min-height: 64px;
    }
    justify-content: flex-end;
  }
  &__listItem {
    border-radius: 4px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    letter-spacing: 0.1px !important;
    &:hover {
      background-color: rgba(48, 156, 234, 0.1);
    }
    &_focus {
      background-color: rgba(48, 156, 234, 0.1);
    }
  }
  &__selected {
    background-color: rgba(48, 156, 234, 0.1) !important;
  }
  &__button {
    color: #309cea !important;
  }
  &__link {
    text-decoration: none;
  }
  &__hide {
    display: none;
  }
}
