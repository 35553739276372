.layout {
  &__root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  &__toolBar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 8px;
    min-height: 56px;
    @media (min-width: 0px) and (orientation: landscape) {
      min-height: 40px;
    }
    @media (min-width: 600px) {
      min-height: 64px;
    }
  }
  &__content {
    flex-grow: 1;
    padding: 24px;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: 0;
  }

  &__contentShift {
    transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
    margin-left: 256px;
  }
}
