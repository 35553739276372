.user-summary {
  &__gridRow {
    justify-content: space-between;
    margin-top: 20px;
  }

  &__title {
    color: #757575;
  }

  &__paper {
    padding: 8px;
  }

  &__label {
    letter-spacing: 0.15px !important;
    padding-bottom: 0px !important;
    font-weight: 600 !important;
  }
}
