.login-component {
  &__root {
    display: flex;
    flex-direction: column;
  }

  &__main {
    width: auto;
    display: block;
    margin-left: 24px;
    margin-right: 24px;
    flex-grow: 1;

    @media (min-width: 448px) {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__paper {
    display: flex;
    margin-top: 64px;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px 24px;
  }

  &__avatar {
    margin: 8px;
    background-color: #f50057 !important;
  }

  &__form {
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
  }

  &__submit {
    margin-top: 24px;
    background-color: #3598d5 !important;
    color: #ffffff !important;
  }
}
