.footer {
  &__root {
    display: flex;
    background: #3598d5;
    height: 32px;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 7px;
  }
}
