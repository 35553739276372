.user-extra-documents {
  &__images {
    margin: 0 20px 0 20px !important;
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  &__red {
    color: #e23328 !important;
  }
  &__green {
    color: #00a41a !important;
  }
  &__yellow {
    color: #e3b930 !important;
  }
  &__buttonReject {
    background: #e23328 !important;
    color: #fff !important;
    margin: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  &__buttonAccept {
    background: #309cea !important;
    color: #fff !important;
    margin: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    letter-spacing: 0.75px !important;
  }

  &__buttonIcon {
    padding-left: 4px;
    margin-right: -10px;
  }
  &__blockButtons {
    text-align: center;
    width: 100%;
  }
}
